// Madani Muhsaf pages:
// 604 pages, each value in array is ID of first ayah on page
// i.e ayah page 2 start with ayah_id = 8

const MUHSAF_PAGES = [1, 8, 13, 24, 32, 37, 45, 56, 65, 69, 77, 84, 91, 96,
  101, 109, 113, 120, 127, 134, 142, 149, 153,
  161, 171, 177, 184, 189, 194, 198, 204, 210, 218, 223, 227,
  232, 238, 241, 245, 253, 256, 260, 264, 267, 272, 277, 282, 289, 290,
  294, 303, 309, 316, 323, 331, 339, 346, 355, 364, 371, 377, 385,
  394, 402, 409, 415, 426, 434, 442, 447, 451, 459, 467, 474, 480,
  488, 494, 500, 505, 508, 513, 517, 520, 527, 531, 538, 545, 553,
  559, 568, 573, 580, 585, 588, 595, 599, 607, 615, 621, 628, 634,
  641, 648, 656, 664, 669, 672, 675, 679, 683, 687, 693, 701, 706, 711,
  715, 720, 727, 734, 740, 747, 753, 760, 765, 773, 778, 783, 790, 798,
  808, 817, 825, 834, 842, 849, 858, 863, 871, 880, 884, 891, 900, 908,
  914, 920, 927, 932, 936, 941, 947, 955, 966, 977, 985, 992, 998, 1006,
  1012, 1022, 1028, 1036, 1042, 1050, 1059, 1075, 1085, 1092, 1098, 1104,
  1110, 1114, 1118, 1125, 1133, 1142, 1150, 1161, 1169, 1177, 1186, 1194,
  1201, 1206, 1213, 1222, 1230, 1236, 1242, 1249, 1256, 1262, 1267, 1272,
  1276, 1283, 1290, 1297, 1304, 1308, 1315, 1322, 1329, 1335, 1342, 1347,
  1353, 1358, 1365, 1371, 1379, 1385, 1390, 1398, 1407, 1418, 1426, 1435,
  1443, 1453, 1462, 1471, 1479, 1486, 1493, 1502, 1511, 1519, 1527, 1536,
  1545, 1555, 1562, 1571, 1582, 1591, 1601, 1611, 1619, 1627, 1634, 1640,
  1649, 1660, 1666, 1675, 1683, 1692, 1700, 1708, 1713, 1721, 1726, 1736,
  1742, 1750, 1756, 1761, 1769, 1775, 1784, 1793, 1803, 1818, 1834, 1854,
  1873, 1893, 1908, 1916, 1928, 1936, 1944, 1956, 1966, 1974, 1981, 1989,
  1995, 2004, 2012, 2020, 2030, 2037, 2047, 2057, 2068, 2079, 2088, 2096,
  2105, 2116, 2126, 2134, 2145, 2156, 2161, 2168, 2175, 2186, 2194, 2202,
  2215, 2224, 2238, 2251, 2262, 2276, 2289, 2302, 2315, 2327, 2346, 2361, 2386,
  2400, 2413, 2425, 2436, 2447, 2462, 2474, 2484, 2494, 2508, 2519, 2528, 2541,
  2556, 2565, 2574, 2585, 2596, 2601, 2611, 2619, 2626, 2634, 2642, 2651, 2660,
  2668, 2674, 2691, 2701, 2716, 2733, 2748, 2763, 2778, 2792, 2802, 2812, 2819,
  2823, 2828, 2835, 2845, 2850, 2853, 2858, 2867, 2876, 2888, 2899, 2911, 2923,
  2933, 2952, 2972, 2993, 3016, 3044, 3069, 3092, 3116, 3139, 3160, 3173, 3182,
  3195, 3204, 3215, 3223, 3236, 3248, 3258, 3266, 3274, 3281, 3288, 3296, 3303,
  3312, 3323, 3330, 3337, 3347, 3355, 3364, 3371, 3379, 3386, 3393, 3404, 3415,
  3425, 3434, 3442, 3451, 3460, 3470, 3481, 3489, 3498, 3504, 3515, 3524, 3534,
  3540, 3549, 3556, 3564, 3569, 3577, 3584, 3588, 3596, 3607, 3614, 3621, 3629,
  3638, 3646, 3655, 3664, 3672, 3679, 3691, 3699, 3705, 3718, 3733, 3746, 3760,
  3776, 3789, 3813, 3840, 3865, 3891, 3915, 3942, 3971, 3987, 3997, 4013, 4032,
  4054, 4064, 4069, 4080, 4090, 4099, 4106, 4115, 4126, 4133, 4141, 4150, 4159,
  4167, 4174, 4183, 4192, 4200, 4211, 4219, 4230, 4239, 4248, 4257, 4265, 4273,
  4283, 4288, 4295, 4304, 4317, 4324, 4336, 4348, 4359, 4373, 4386, 4399, 4415,
  4433, 4454, 4474, 4487, 4496, 4506, 4516, 4525, 4531, 4539, 4546, 4557, 4565,
  4575, 4584, 4593, 4599, 4607, 4612, 4617, 4624, 4631, 4646, 4666, 4682, 4706,
  4727, 4750, 4767, 4785, 4811, 4829, 4853, 4874, 4896, 4920, 4943, 4971, 4996,
  5030, 5056, 5079, 5087, 5094, 5100, 5105, 5111, 5116, 5126, 5130, 5136, 5143,
  5151, 5156, 5162, 5169, 5178, 5186, 5193, 5200, 5209, 5218, 5223, 5230, 5237,
  5242, 5254, 5268, 5288, 5314, 5332, 5359, 5386, 5416, 5430, 5448, 5461, 5476,
  5495, 5514, 5543, 5571, 5597, 5617, 5642, 5673, 5703, 5729, 5759, 5799, 5830,
  5853, 5882, 5909, 5932, 5959, 5990, 6016, 6042, 6068, 6093, 6119, 6136, 6152, 6177,
  6194, 6208, 6222]

// Count of ayah in each chapter
const SURAH_AYAH = [7, 286, 200, 176, 120, 165, 206, 75, 129, 109, 123, 111, 43, 52,
  99, 128, 111, 110, 98, 135, 112, 78, 118, 64, 77, 227, 93, 88, 69, 60, 34, 30, 73,
  54, 45, 83, 182, 88, 75, 85, 54, 53, 89, 59, 37, 35, 38, 29, 18, 45, 60, 49, 62,
  55, 78, 96, 29, 22, 24, 13, 14, 11, 11, 18, 12, 12, 30, 52, 52, 44, 28, 28, 20,
  56, 40, 31, 50, 40, 46, 42, 29, 19, 36, 25, 22, 17, 19, 26, 30, 20, 15, 21, 11, 8,
  8, 19, 5, 8, 8, 11, 11, 8, 3, 9, 5, 4, 7, 3, 6, 3, 5, 4, 5, 6]

const ABS_AYAH = [
  0, 7, 293, 493, 669, 789, 954, 1160, 1235, 1364, 1473, 1596, 1707, 1750, 1802, 1901, 2029, 2140, 2250,
  2348, 2483, 2595, 2673, 2791, 2855, 2932, 3159, 3252, 3340, 3409, 3469, 3503, 3533, 3606, 3660, 3705,
  3788, 3970, 4058, 4133, 4218, 4272, 4325, 4414, 4473, 4510, 4545, 4583, 4612, 4630, 4675, 4735, 4784,
  4846, 4901, 4979, 5075, 5104, 5126, 5150, 5163, 5177, 5188, 5199, 5217, 5229, 5241, 5271, 5323, 5375,
  5419, 5447, 5475, 5495, 5551, 5591, 5622, 5672, 5712, 5758, 5800, 5829, 5848, 5884, 5909, 5931, 5948,
  5967, 5993, 6023, 6043, 6058, 6079, 6090, 6098, 6106, 6125, 6130, 6138, 6146, 6157, 6168, 6176, 6179,
  6188, 6193, 6197, 6204, 6207, 6213, 6216, 6221, 6225, 6230
]

function getAyahKeyFromId(verseId) {
  let surah, ayah, index;

  for(index=0; index < SURAH_AYAH.length; index += 1){

    if(verseId <= SURAH_AYAH[index]){
      surah = index + 1
      ayah = verseId
      break;
    } else{
      verseId -= SURAH_AYAH[index]
    }
  }

  return getAyahKey(surah, ayah)
}

function getAyahNumberFromKey(key) {
  const parts = key.split(':')

  return parts[1];
}
function getAyahNumberFromId(id) {
  const key =  getAyahKeyFromId(id)
  return getAyahNumberFromKey(key);
}


function getAyahIdFromKey(key) {
  const parts = key.split(':')

  return getAyahId(parseInt(parts[0]), parseInt(parts[1]))
}

function getAyahId(surah, ayah) {
  surah = parseInt(surah);
  ayah = parseInt(ayah);

  if (isValid(surah, ayah))
    return ABS_AYAH[surah - 1] + ayah
}

function getAyahKey(surah, ayah) {
  if (isValid(surah, ayah))
    return `${surah}:${ayah}`
}

/*
Get Madani Muhsaf page number with ayah ID
 */
function getPageFromId(ayahId) {
  let page = 0

  while (MUHSAF_PAGES[page] && ayahId >= MUHSAF_PAGES[page]) {
    page++;
  }

  return page
}

function getPageFromKey(ayahKey) {
  const id = getAyahIdFromKey(ayahKey)

  return getPageFromId(id)
}

function isValid(surah, ayah) {
  return ayah > 0 && SURAH_AYAH[surah - 1] && ayah <= SURAH_AYAH[surah - 1]
}

export {
  isValid,
  getPageFromId,
  getPageFromKey,
  getAyahKey,
  getAyahId,
  getAyahIdFromKey,
  getAyahNumberFromKey,
  getAyahKeyFromId,
  getAyahNumberFromId
}
